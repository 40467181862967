import React, { useState, useEffect } from "react";

import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import Login from "../pages/Login";

import UserMenu from "../partials/header/UserMenu";

import axios from "axios";

import { Link } from "react-router-dom";

import Barchart from "../charts/BarChart";
import Linechart from "../charts/LineChart";
import Smsalert from "./Smsalert";
import ProgressChart from "../charts/ProgressChart";
import PieChart from "../charts/PieChart";

import { FaPowerOff, FaPowerOn } from 'react-icons/fa';

function Dashboard() {
  //TODO: OFFSET VALUE
  const offset = 110;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isOn1, setIsOn1] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    // new Date().toISOString().split("T")[0]
  );

  const SendString = localStorage.getItem("sendmsg");
  const sendmsg = eval("(" + SendString + ")");
  // {"type":"command","id":1,"cmd":"mod_cmd","value":"070600210001"}
  let msgon = { type: "command", id: 1, cmd: "mod_cmd", value: "070600210001" };
  let msgoff = {
    type: "command",
    id: 1,
    cmd: "mod_cmd",
    value: "070600220001",
  };
  const [ison, setIson] = useState(false);

  let [resultlatest, setResultLatest] = useState([]);
  let [mqttdata, setMqttData] = useState([]);
  let [kajal,setKajal]= useState([]);

  let panelinfo = JSON.parse(localStorage.getItem("data"));
  // console.log(panelinfo);

  resultlatest = JSON.parse(localStorage.getItem("resultlatest"));
  mqttdata = JSON.parse(localStorage.getItem("mqttdata"));
  kajal = JSON.parse(localStorage.getItem("kajal"));

  // console.log(resultlatest);

  // console.log(currentime);

  setInterval(() => {
    let resultlatest = JSON.parse(localStorage.getItem("resultlatest"));
    let mqttdata = JSON.parse(localStorage.getItem("mqttdata"));
    let kajal = JSON.parse(localStorage.getItem("kajal"));

    setResultLatest(resultlatest);
    setMqttData(mqttdata);
    setKajal(kajal);
    //TODO:PUMP STATUS INDEX
    setIson(
      mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][offset + 32] === 1
          ? true
          : false
        : false
    );
  }, 5000);
  // console.log(ison);

  const handleClick = () => {
    const confirmation = window.confirm(
      `Are you sure you want to switch ${
        ison === true ? "OFF" : "ON"
      } the pump?`
    );
    if (confirmation) {
      const message = ison ? msgoff : msgon;
      sendmsg(panelinfo.imei, message);
    }

    console.log("reached at pump status!, button clicked");
  };

  // console.log(mqttdata[panelinfo.cardid] ? mqttdata[panelinfo.cardid] : "0");

  // console.log("imei: ", panelinfo.imei, " ", "deviceid", panelinfo.deviceid);

  // console.log(mqttdata);

  /**apis starts here - analytical data */
  let loginToken = JSON.parse(localStorage.getItem("loginToken"));
  // console.log(loginToken);

  //TODO:LOOADIN
  const handleChange = (event) => {
    const confirmation = window.confirm(`ARE YOU SURE TO CHANGE THE DATE?`);
    if (confirmation) {
      setSelectedDate(event.target.value);
    }
    const selectedDate = event.target.value;
    setSelectedDate(selectedDate);
  };

  const headers = {
    // Authorization: `Bearer ${loginToken}`,
    Authorization: "Bearer" + " " + loginToken,
    "Content-Type": "application/json",
  };
  const data = {
    device_id: panelinfo.deviceid,
    // date: "2023-04-27",
    date: selectedDate,
  };
  // console.log(data);

  let [timeArray, setTimeArray] = useState([]);
  let time = [];
  let msgArray = [];
  let message = [];
  let dataArr = [];
  let [powerArr, setPowerArr] = useState([]);
  let [vAvg, setVavg] = useState([]);
  let [iAvg, setIAvg] = useState([]);
  let [EnergyArr, setEnergyArr] = useState([]);
  let [avgPfArr, setAvgPfArr] = useState([]);
  let [alertArr, setAlertArr] = useState([]);
  let [alertTime, setAlertTime] = useState([]);

  function stringToArray(str) {
    const arr = str.split(",");
    return arr;
  }

  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );

  function convertTo24Hour(timeString) {
    if (!timeString) {
      // Handle null or invalid values
      return "00:00:00";
    }
   
    // const time = new Date("1970-01-01 " + timeString);
    const time = new Date(`1970-01-01T${timeString}`);

     // Check if the date is valid
  if (isNaN(time.getTime())) {
    console.error("Invalid date:", timeString);
    return "00:00:00"; // Provide a default value
  }
    let hours = time.getHours();
    const isPM = hours >= 12;
    hours = (hours % 12) + (isPM ? 12 : 0);
    const minutes = time.getMinutes().toString().padStart(2, "0");
    const seconds = time.getSeconds().toString().padStart(2, "0");
    const time24 =
      hours.toString().padStart(2, "0") + ":" + minutes + ":" + seconds;

    return time24;
  }
  // console.log(convertTo24Hour(currentTime));
  
  // let currentime = convertTo24Hour(
  //   JSON.parse(localStorage.getItem("currentime"))
  // );
  // const storedTime = JSON.parse(localStorage.getItem("currentime"));
// console.log("Stored Time:", storedTime);
// console.log("Converted Time:", currentTime);


  // console.log(currentime);

  useEffect(() => {
    axios
      .post(
        "https://vidani.smart-iot.in/api/super-admin/device/getdevicelogs",
        data,
        { headers }
      )
      .then(function (response) {
        // console.log(response.data.data);
        let x = response.data.data;
        let log = x.logs;
        // console.log(log[0].log_time);
        // console.log(logs.length);
        timeArray = [];
        for (let i = 0; i < log.length; i++) {
          //accessing log_time from the log (api)
          let y = log[i].log_time;
          time = new Date(y).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: "Asia/Kolkata",
          });
          timeArray.push((time));

          //accessing msg from the log (api)
          let w = log[i].msg;
          message.push(stringToArray(w));

          //to decrpt
          let array_index = 0;
          msgArray = [];
          for (let index = 0; index < 200; index++) {
            array_index = index * 2;
            msgArray[index] =
              Number(message[i][array_index]) * 256 +
              Number(message[i][array_index + 1]);

            dataArr[i] = msgArray;
            // console.log(dataArr[i]);
          }
        }
        setTimeArray(timeArray);
        // console.log(timeArray);
        // console.log(dataArr);
        for (let i = 0; i < dataArr.length; i++) {
          avgPfArr[i] = dataArr[i][30] / 100;
          powerArr[i] = dataArr[i][14];
          vAvg[i] = dataArr[i][3];
          iAvg[i] = dataArr[i][11];
          EnergyArr[i] = dataArr[i][12] * 65536 + dataArr[i][13];
          // alertArr[i] = [
          //   dataArr[i][61],
          //   dataArr[i][62],
          //   dataArr[i][63],
          //   dataArr[i][64],
          //   dataArr[i][65],
          //   dataArr[i][66],
          // ];
          alertArr[i] = [
            { vl1_fault: dataArr[i][61], time: timeArray[i] },
            { vl2_fault: dataArr[i][62], time: timeArray[i] },
            { vl3_fault: dataArr[i][63], time: timeArray[i] },
            { il1_fault: dataArr[i][64], time: timeArray[i] },
            { il2_fault: dataArr[i][65], time: timeArray[i] },
            { il3_fault: dataArr[i][66], time: timeArray[i] },
          ];

          // alertArr[i] = dataArr[i][61];
        }
        setVavg(vAvg);
        setIAvg(iAvg);
        setPowerArr(powerArr);
        setEnergyArr(EnergyArr);
        setAvgPfArr(avgPfArr);
        setAlertArr(alertArr);
        // console.log("hello from axios");
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [selectedDate, panelinfo.deviceid]);

  // console.log(alertArr);
  let extractedFaults = [];
  for (let i = 0; i < alertArr.length; i++) {
    const faultObj = alertArr[i];
    const time1 = faultObj[0].time;
    if (
      faultObj[0].vl1_fault === 1 ||
      faultObj[1].vl2_fault === 1 ||
      faultObj[2].vl3_fault === 1
    ) {
      extractedFaults.push({ fault: "under voltage fault", time1 });
    }
    if (
      faultObj[0].il1_fault === 1 ||
      faultObj[1].il2_fault === 1 ||
      faultObj[2].il3_fault === 1
    ) {
      extractedFaults.push({ fault: "under current fault", time1 });
    }
    if (
      faultObj[0].vl1_fault === 2 ||
      faultObj[1].vl2_fault === 2 ||
      faultObj[2].vl3_fault === 2
    ) {
      extractedFaults.push({ fault: "Over Voltage fault", time1 });
    }
    if (
      faultObj[0].il1_fault === 2 ||
      faultObj[1].il2_fault === 2 ||
      faultObj[2].il3_fault === 2
    ) {
      extractedFaults.push({ fault: "Over Current fault", time1 });
    }
    if (
      faultObj[0].vl1_fault === 3 ||
      faultObj[1].vl2_fault === 3 ||
      faultObj[2].vl3_fault === 3
    ) {
      extractedFaults.push({ fault: "Imbalance voltage fault", time1 });
    }
    if (
      faultObj[0].il1_fault === 3 ||
      faultObj[1].il2_fault === 3 ||
      faultObj[2].il3_fault === 3
    ) {
      extractedFaults.push({ fault: "Imbalance current fault", time1 });
    }
  }
  // console.log(extractedFaults);
  if (extractedFaults) {
    localStorage.setItem("extractedFaults", JSON.stringify(extractedFaults));
  }

  // let alertTextArr = [];
  // for (let i = 0; i < alertArr.length; i++) {
  //   for (let j = 0; j < 6; j++) {
  //     if (alertArr[i][j] === 1) {
  //       alertTextArr[i] = "under Voltage Fault";
  //       // console.log("under fault");

  //       alertTime[i] = timeArray[i];
  //       console.log("under voltage");
  //     }

  //     else if (alertArr[i][j] === 2) {
  //       alertTextArr[i] = "Over Voltage Fault";
  //       alertTime[i] = timeArray[i];
  //       console.log("over voltage");
  //     }

  //     else if (alertArr[i][j] === 3) {
  //       alertTextArr[i] = "Imbalance Voltage Fault";
  //       alertTime[i] = timeArray[i];
  //       console.log("imbalnce");
  //     }

  //   }
  // }
  // console.log(alertTextArr);
  // console.log(alertTime);

  // console.log(timeArray);
  // console.log(dataArr);
  // console.log(EnergyArr);
  // console.log(avgPfArr);
  // new optimized array
  let avgCvalueArray = [];
  let avgCtimeArray = [];

  let avgVvalueArray = [];
  let avgVtimeArray = [];

  let avgPFvalueArray = [];
  let avgPFtimeArray = [];

  let powervalueArray = [];
  let powertimeeArray = [];

  let energyvalueArray = [];
  let energytimeArray = [];

  // new optimized array
  for (let i = 0; i < timeArray.length / 30; i++) {
    let trueIndex = 0;
    for (let j = 0; j < 30; j++) {
      trueIndex = i * 30 + j;
      if (
        iAvg[trueIndex] - iAvg[trueIndex + 1] > 1 ||
        iAvg[trueIndex + 1] - iAvg[trueIndex] > 1
      ) {
        avgCvalueArray.push(iAvg[trueIndex]);
        avgCtimeArray.push(timeArray[trueIndex]);
      }
      if (
        vAvg[trueIndex] - vAvg[trueIndex + 1] > 1 ||
        vAvg[trueIndex + 1] - vAvg[trueIndex] > 1
      ) {
        avgVvalueArray.push(vAvg[trueIndex]);
        avgVtimeArray.push(timeArray[trueIndex]);
      }
      if (
        powerArr[trueIndex] - powerArr[trueIndex + 1] > 10 ||
        powerArr[trueIndex + 1] - powerArr[trueIndex] > 10
      ) {
        powervalueArray.push(powerArr[trueIndex]);
        powertimeeArray.push(timeArray[trueIndex]);
      }
      if (
        avgPfArr[trueIndex] - avgPfArr[trueIndex + 1] > 0.1 ||
        avgPfArr[trueIndex + 1] - avgPfArr[trueIndex] > 0.1
      ) {
        avgPFvalueArray.push(avgPfArr[trueIndex]);
        avgPFtimeArray.push(timeArray[trueIndex]);
      }
    }
    let trueIndex1 = i * 30 + 30;
    if (trueIndex1 == trueIndex) {
      trueIndex1++;
      console.log("hellow from true");
    }
    if (trueIndex1 <= timeArray.length) {
      avgCvalueArray.push(iAvg[trueIndex1]);
      avgCtimeArray.push(timeArray[trueIndex1]);

      avgVvalueArray.push(vAvg[trueIndex1]);
      avgVtimeArray.push(timeArray[trueIndex1]);

      powervalueArray.push(powerArr[trueIndex1]);
      powertimeeArray.push(timeArray[trueIndex1]);

      avgPFvalueArray.push(avgPfArr[trueIndex1]);
      avgPFtimeArray.push(timeArray[trueIndex1]);
    }
  }
  // console.log(timeArray);
  // console.log(avgCvalueArray);
  // console.log(avgCtimeArray);

  let timeHour = [];
  let energyPerHour = [];

  for (let i = 0; i < EnergyArr.length - 1; i += 60) {
    const start = Math.max(0, i - 60);
    const end = Math.min(i + 60, EnergyArr.length - 1);
    energyPerHour.push(EnergyArr[end] - EnergyArr[start]);
    timeHour.push(timeArray[end]);
  }
  // console.log(timeHour);
  // console.log(energyPerHour);
  // console.log(mqttdata?.[panelinfo.cardid]?.[61]);
  // console.log(
  //   (mqttdata[panelinfo.cardid][12] * 65536 + mqttdata[panelinfo.cardid][13]) /
  //     1000
  // );

  // TIMER-1 PERCENTAGE
  let Timer1per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][40] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][51] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][40] === 0
          ? 1
          : mqttdata[panelinfo.cardid][40]
        : 1)) *
    100;
  // TIMER-2 PERCENTAGE
  let Timer2per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][41] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][52] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][41] === 0
          ? 1
          : mqttdata[panelinfo.cardid][41]
        : 1)) *
    100;
  // TIMER-3 PERCENTAGE
  let Timer3per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][42] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][53] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][42] === 0
          ? 1
          : mqttdata[panelinfo.cardid][42]
        : 1)) *
    100;
  // TIMER-4 PERCENTAGE
  let Timer4per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][43] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][54] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][43] === 0
          ? 1
          : mqttdata[panelinfo.cardid][43]
        : 1)) *
    100;
  // TIMER-5 PERCENTAGE
  let Timer5per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][44] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][55] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][44] === 0
          ? 1
          : mqttdata[panelinfo.cardid][44]
        : 1)) *
    100;

  // TIMER-6 PERCENTAGE
  let Timer6per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][45] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][56] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][45] === 0
          ? 1
          : mqttdata[panelinfo.cardid][45]
        : 1)) *
    100;
  // TIMER-7 PERCENTAGE
  let Timer7per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][46] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][57] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][46] === 0
          ? 1
          : mqttdata[panelinfo.cardid][46]
        : 1)) *
    100;
  // TIMER-8 PERCENTAGE
  let Timer8per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][47] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][58] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][47] === 0
          ? 1
          : mqttdata[panelinfo.cardid][47]
        : 1)) *
    100;
  // TIMER-9 PERCENTAGE
  let Timer9per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][48] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][59] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][48] === 0
          ? 1
          : mqttdata[panelinfo.cardid][48]
        : 1)) *
    100;
  // TIMER-10 PERCENTAGE
  let Timer10per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][49] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][60] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][49] === 0
          ? 1
          : mqttdata[panelinfo.cardid][49]
        : 1)) *
    100;

  // console.log(Timer1per);
  // console.log(Timer2per);
  // console.log(Timer3per);
  // console.log(Timer4per);
  // console.log(Timer5per);
  // console.log(Timer6per);
  // console.log(Timer7per);
  // console.log(Timer8per);
  // console.log(Timer9per);
  // console.log(Timer10per);
  let realCurrentIL1 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][8] + 0.1
    : 0.1;
  let realCurrentIL2 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][9] + 0.1
    : 0.1;
  let realCurrentIL3 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][10] + 0.1
    : 0.1;
  let realVoltageVL1 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][0]
    : 1;
  let realVoltageVL2 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][1]
    : 1;
  let realVoltageVL3 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][2]
    : 1;
  let realpf1 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][27] / 100
    : 1;
  let realpf2 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][28] / 100
    : 1;
  let realpf3 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][29] / 100
    : 1;
    

//todo: configurtion code below

let [padval, setPadval] = useState('');
   function func(val){
    let minsArr = val.split(":");
    let mins = Number(minsArr[0]) * 60 + Number(minsArr[1]);
    padval = mins.toString().padStart(5,"0");
    // console.log(padval)
    setPadval(padval)
    return padval
   }

let [padval2, setpadval2]  = useState('');
function func2(val){
  
  padval2 = val.toString().padStart(5,"0");
  // console.log(padval)
  setpadval2(padval2)
  return padval2
 }

  
    function convertToHM(value) {
      const hours = Math.floor(value / 60);
      const remainingMinutes = value % 60;
      const hours24 = hours % 24;
      return `${hours24.toString().padStart(2, "0")}:${remainingMinutes
        .toString()
        .padStart(2, "0")}`;
    }
    
    // function convertToHM(value) {
    //   // Get the local timezone offset in minutes
    //   const timeZoneOffset = new Date().getTimezoneOffset();
    
    //   // Adjust the input value with the timezone offset
    //   const adjustedValue = value + timeZoneOffset;
    
    //   // Calculate hours and remaining minutes
    //   const hours = Math.floor(adjustedValue / 60);
    //   const remainingMinutes = adjustedValue % 60;
    
    //   // Ensure hours are within the 24-hour format
    //   const hours24 = (hours % 24 + 24) % 24;
    
    //   // Format the result as a string
    //   return `${hours24.toString().padStart(2, "0")}:${remainingMinutes
    //     .toString()
    //     .padStart(2, "0")}`;
    // }


//TODO:SLOTS TIMER CONFIG

    // slot1 
    let [slot1startT, setSlot1startT] = useState(
      kajal?.[8]
        ? convertToHM(kajal?.[8])
        : "00:00"
    );
    let [slot1stopT, setSlot1stopT] = useState(
      kajal?.[12]
        ? convertToHM(kajal?.[12])
        : "00:00"
    );
// slot2 
    let [slot2startT, setSlot2startT] = useState(
      kajal?.[9]
        ? convertToHM(kajal?.[9])
        : "00:00"
    );
    let [slot2stopT, setSlot2stopT] = useState(
      kajal?.[13]
        ? convertToHM(kajal?.[13])
        : "00:00"
    );
    // slot3 
    let [slot3startT, setSlot3startT] = useState(
      kajal?.[10]
        ? convertToHM(kajal?.[10])
        : "00:00"
    );
    let [slot3stopT, setSlot3stopT] = useState(
      kajal?.[14]
        ? convertToHM(kajal?.[14])
        : "00:00"
    );
    // slot4
    let [slot4startT, setSlot4startT] = useState(
      kajal?.[11]
        ? convertToHM(kajal?.[11])
        : "00:00"
    );
    let [slot4stopT, setSlot4stopT] = useState(
      kajal?.[15]
        ? convertToHM(kajal?.[15])
        : "00:00"
    );

    //TODO: SOLENOID TIMER CONFIG
    // sv1
    let [sv1startT, setsv1startT] = useState(
      kajal?.[19]
        ? (kajal?.[19])
        : "0"
    );
    // sv2
    let [sv2startT, setsv2startT] = useState(
      kajal?.[20]
        ? (kajal?.[20])
        : "0"
    );
    // sv3
    let [sv3startT, setsv3startT] = useState(
      kajal?.[21]
        ? (kajal?.[21])
        : "0"
    );
    // sv4
    let [sv4startT, setsv4startT] = useState(
      kajal?.[22]
        ? (kajal?.[22])
        : "0"
    );
    // sv5
    let [sv5startT, setsv5startT] = useState(
      kajal?.[23]
        ? (kajal?.[23])
        : "0"
    );
    // sv6
    let [sv6startT, setsv6startT] = useState(
      kajal?.[24]
        ? (kajal?.[24])
        : "0"
    );
    // sv7
    let [sv7startT, setsv7startT] = useState(
      kajal?.[25]
        ? (kajal?.[25])
        : "0"
    );
   

    
    // slot1 
    const handleslot1StartT = (e) => {
      const value = e.target.value;
      setSlot1startT(value); 
      console.log(value);
      (func(value));
    };

    const handleslot1stopT = (e) => {
      const value = e.target.value;
      setSlot1stopT(value); 
      console.log(value);
      (func(value));
    };
// slot2
    const handleslot2StartT = (e) => {
      const value = e.target.value;
      setSlot2startT(value); 
      console.log(value);
      (func(value));
    };

    const handleslot2stopT = (e) => {
      const value = e.target.value;
      setSlot2stopT(value); 
      console.log(value);
      (func(value));
    };

  // slot3
  const handleslot3StartT = (e) => {
    const value = e.target.value;
    setSlot3startT(value); 
    console.log(value);
    (func(value));
  };

  const handleslot3stopT = (e) => {
    const value = e.target.value;
    setSlot3stopT(value); 
    console.log(value);
    (func(value));
  };

   // slot4
   const handleslot4StartT = (e) => {
    const value = e.target.value;
    setSlot4startT(value); 
    console.log(value);
    (func(value));
  };

  const handleslot4stopT = (e) => {
    const value = e.target.value;
    setSlot4stopT(value); 
    console.log(value);
    (func(value));
  };

    //TODO:SOLENOID HANDLE TIMER

    const handlesv1startT = (e) => {
      const value = e.target.value;
      setsv1startT(value); 
      console.log(value);
      (func2(value));
    };
    const handlesv2startT = (e) => {
      const value = e.target.value;
      setsv2startT(value); 
      console.log(value);
      (func2(value));
    };
    const handlesv3startT = (e) => {
      const value = e.target.value;
      setsv3startT(value); 
      console.log(value);
      (func2(value));
    };
    const handlesv4startT = (e) => {
      const value = e.target.value;
      setsv4startT(value); 
      console.log(value);
      (func2(value));
    };
    const handlesv5startT = (e) => {
      const value = e.target.value;
      setsv5startT(value); 
      console.log(value);
      (func2(value));
    };
    const handlesv6startT = (e) => {
      const value = e.target.value;
      setsv6startT(value); 
      console.log(value);
      (func2(value));
    };
    const handlesv7startT = (e) => {
      const value = e.target.value;
      setsv7startT(value); 
      console.log(value);
      (func2(value));
    };


    //TODO: ALERT

    //TODO:MANUAL START CMD
    let manualStartMessage = {
      type: "control",
      id: 1,
      key: 1,
      value: 1,
    };

    let manualStopMessage = {
      type: "control",
      id: 1,
      key: 1,
      value: 0,
    };
    
    const handleManualStart = () => {
      // Display a confirmation dialog
      const isConfirmed = window.confirm('Are you sure you want to start manually?');
  
      // If the user confirms, send the manual start message
      if (isConfirmed) {
        sendmsg(panelinfo.imei, manualStartMessage);
      }
      // If the user cancels, do nothing
    };

    const handleManualStop = () => {
      // Display a confirmation dialog
      const isConfirmed = window.confirm('Are you sure you want to stop manually?');
  
      // If the user confirms, send the manual start message
      if (isConfirmed) {
        sendmsg(panelinfo.imei, manualStopMessage);
      }
      // If the user cancels, do nothing
    };
    
    //todo:slot msg config
    // console.log(paddedslot1start);
    // console.log(padval)
    //slot1msg
    let slot1startTMessage = {
      type: "config",
      id: 1,
      cmd: "modbus",
      value: `100,001,003,${padval},006`,
    };
    let slot1stopTMessage = {
      type: "config",
      id: 1,
      cmd: "modbus",
      value: `104,001,003,${padval},006`,
    };
    // console.log(slot1stopTMessage);

    //slot2msg
    let slot2startTMessage = {
      type: "config",
      id: 1,
      cmd: "modbus",
      value: `101,001,003,${padval},006`,
    };
    let slot2stopTMessage = {
      type: "config",
      id: 1,
      cmd: "modbus",
      value: `105,001,003,${padval},006`,
    };
    // console.log(slot2stopTMessage);

    //slot3msg
    let slot3startTMessage = {
      type: "config",
      id: 1,
      cmd: "modbus",
      value: `102,001,003,${padval},006`,
    };
    let slot3stopTMessage = {
      type: "config",
      id: 1,
      cmd: "modbus",
      value: `106,001,003,${padval},006`,
    };
    // console.log(slot3stopTMessage);

    //slot4msg
    let slot4startTMessage = {
      type: "config",
      id: 1,
      cmd: "modbus",
      value: `103,001,003,${padval},006`,
    };
    let slot4stopTMessage = {
      type: "config",
      id: 1,
      cmd: "modbus",
      value: `107,001,003,${padval},006`,
    };


    //todo:solenoid config msg
    // console.log(padval2)
    let sv1startTMessage = {
      type: "config",
      id: 1,
      cmd: "modbus",
      value: `120,001,003,${padval2},006`,
    };
    let sv2startTMessage = {
      type: "config",
      id: 1,
      cmd: "modbus",
      value: `121,001,003,${padval2},006`,
    };
    let sv3startTMessage = {
      type: "config",
      id: 1,
      cmd: "modbus",
      value: `122,001,003,${padval2},006`,
    };
    let sv4startTMessage = {
      type: "config",
      id: 1,
      cmd: "modbus",
      value: `123,001,003,${padval2},006`,
    };
    let sv5startTMessage = {
      type: "config",
      id: 1,
      cmd: "modbus",
      value: `124,001,003,${padval2},006`,
    };
    let sv6startTMessage = {
      type: "config",
      id: 1,
      cmd: "modbus",
      value: `125,001,003,${padval2},006`,
    };
    let sv7startTMessage = {
      type: "config",
      id: 1,
      cmd: "modbus",
      value: `126,001,003,${padval2},006`,
    };
    // console.log(sv1startTMessage)
    
    
 //todo: configurtion code abve 
    
  return (
    <div className="flex h-screen overflow-hidden bg-indigo-700">
      {/* Content area */}

      <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
        <header className="sticky top-0 z-30 bg-[#3473e0] p-1  flex flex-wrap justify-end items-center gap-2 ">
          {/* <div className="grid justify-start grid-flow-col gap-2 sm:auto-cols-max sm:justify-end">
            <label htmlFor="datepicker" className="sr-only">
              Select Date
            </label>
            <input
              type="date"
              id="datepicker"
              value={selectedDate}
              onChange={handleChange}
              className="px-3 py-2 mt-2 border rounded-lg sm:w-52 lg:w-56 md:w-60 xl:w-60 2xl:w-60"
            />
          </div> */}
          {/* <div>
            <Link to="/Table">
              <button className="px-3 py-2 mt-2 text-black bg-white border rounded-lg shadow-lg shadow-gray-600 sm:w-52 lg:w-56 md:w-60 xl:w-60 2xl:w-60">
                Configuration
              </button>
            </Link>
          </div> */}
          <div className="p-1">
            <UserMenu />
          </div>
        </header>

        <main className="p-0 m-0 ">
          <div className="w-full p-2 py-2 m-auto mb-10 sm:px-6 lg:px-8 ">
            {/* Welcome banner */}
            {/* <button>Latest Data Arrival Time: {currentime}</button> */}
            <WelcomeBanner />

            {/* statuses cards */}
            <div className="flex flex-col flex-wrap p-4 my-3 mb-2 font-sans bg-gray-200 rounded-lg shadow-md bg-opacity-70 backdrop-filter backdrop-blur-lg">
              <div className="flex flex-wrap ">
                
                {/* pump status  */}
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow px-5 py-8 m-2 text-white bg-indigo-500 f fle btn hover:bg-indigo ">
                  <div className="mb-1 text-xl font-bold text-center text-white">
                   PUMP
                  </div>
                  <div
                    className={`p-0 border border-green-700 ${
                      (kajal?.[7]===1?"bg-green-700":"bg-red-700")
                        
                    } rounded-lg shadow-lg`}
                  >
                    <button className="px-5"></button>
                  </div>
                  
                  
                </div>
                {/* vl1 status */}
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow px-5 py-8 m-2 text-white bg-indigo-500 f fle btn hover:bg-indigo">
                  <div className="mb-1 text-xl font-bold text-center text-white">
                    SOLENOID VALVE 1
                  </div>
                  <div
                    className={`p-0 border border-green-700 ${
                      (kajal?.[0]===1?"bg-green-700":"bg-red-700")
                        
                    } rounded-lg shadow-lg`}
                  >
                    <button className="px-5"></button>
                  </div>
                  {/* <div>
                    <button
                      className={`mx-2 px-2 text-lg font-bold rounded-lg shadow-sm shadow-white ${
                        ison
                          ? "bg-green-600 text-white"
                          : "bg-red-600 text-white"
                      }`}
                      onClick={handleClick}
                    >
                      {ison ? "ON" : "OFF"}
                    </button>
                  </div> */}
                  
                </div>
                {/* vl2 status */}
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow px-5 py-8 m-2 text-white bg-indigo-500 f fle btn hover:bg-indigo ">
                  <div className="mb-1 text-xl font-bold text-center text-white">
                  SOLENOID VALVE 2
                  </div>
                  <div
                    className={`p-0 border border-green-700 ${
                      (kajal?.[1]===1?"bg-green-700":"bg-red-700")
                        
                    } rounded-lg shadow-lg`}
                  >
                    <button className="px-5"></button>
                  </div>
                  {/* <div>
                    <button
                      className={`mx-2 px-2 text-lg font-bold rounded-lg shadow-sm shadow-white ${
                        ison
                          ? "bg-green-600 text-white"
                          : "bg-red-600 text-white"
                      }`}
                      onClick={handleClick}
                    >
                      {ison ? "ON" : "OFF"}
                    </button>
                  </div> */}
                </div>
                {/* vl3 status */}
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow px-5 py-8 m-2 text-white bg-indigo-500 f fle btn hover:bg-indigo ">
                  <div className="mb-1 text-xl font-bold text-center text-white">
                  SOLENOID VALVE 3
                  </div>
                  <div
                    className={`p-0 border border-green-700 ${
                      (kajal?.[2]===1?"bg-green-700":"bg-red-700")
                        
                    } rounded-lg shadow-lg`}
                  >
                    <button className="px-5"></button>
                  </div>
 {/* <div>
                    <button
                      className={`mx-2 px-2 text-lg font-bold rounded-lg shadow-sm shadow-white ${
                        ison
                          ? "bg-green-600 text-white"
                          : "bg-red-600 text-white"
                      }`}
                      onClick={handleClick}
                    >
                      {ison ? "ON" : "OFF"}
                    </button>
                  </div> */}
                  {/* <div
                    className={`p-0 border border-green-700 ${
                      (mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][offset + 43]
                        : 1) === 0
                        ? "bg-green-400"
                        : "bg-red-600"
                    } rounded-lg shadow-lg`}
                  >
                    <button className="px-5"></button>
                  </div> */}
                </div>
                {/* Il1 status */}
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow px-5 py-8 m-2 text-white bg-indigo-500 f fle btn hover:bg-indigo ">
                  <div className="mb-1 text-xl font-bold text-center text-white">
                  SOLENOID VALVE 4
                  </div>
                  <div
                    className={`p-0 border border-green-700 ${
                      (kajal?.[3]===1?"bg-green-700":"bg-red-700")
                        
                    } rounded-lg shadow-lg`}
                  >
                    <button className="px-5"></button>
                  </div>
                  {/* <div>
                    <button
                      className={`mx-2 px-2 text-lg font-bold rounded-lg shadow-sm shadow-white ${
                        ison
                          ? "bg-green-600 text-white"
                          : "bg-red-600 text-white"
                      }`}
                      onClick={handleClick}
                    >
                      {ison ? "ON" : "OFF"}
                    </button>
                  </div> */}
                  {/* <div
                    className={`p-0 border border-green-700 ${
                      (mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][offset + 44]
                        : 1) === 0
                        ? "bg-green-400"
                        : "bg-red-600"
                    } rounded-lg shadow-lg`}
                  >
                    <button className="px-5"></button>
                  </div> */}
                </div>
                {/* IL2 status */}
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow px-5 py-8 m-2 text-white bg-indigo-500 f fle btn hover:bg-indigo ">
                  <div className="mb-1 text-xl font-bold text-center text-white">
                  SOLENOID VALVE 5
                  </div>
                  <div
                    className={`p-0 border border-green-700 ${
                      (kajal?.[4]===1?"bg-green-700":"bg-red-700")
                        
                    } rounded-lg shadow-lg`}
                  >
                    <button className="px-5"></button>
                  </div>
                  {/* <div>
                    <button
                      className={`mx-2 px-2 text-lg font-bold rounded-lg shadow-sm shadow-white ${
                        ison
                          ? "bg-green-600 text-white"
                          : "bg-red-600 text-white"
                      }`}
                      onClick={handleClick}
                    >
                      {ison ? "ON" : "OFF"}
                    </button>
                  </div> */}
                  {/* <div
                    className={`p-0 border border-green-700 ${
                      (mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][offset + 45]
                        : 1) === 0
                        ? "bg-green-400"
                        : "bg-red-600"
                    } rounded-lg shadow-lg`}
                  >
                    <button className="px-5"></button>
                  </div> */}
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow px-5 py-8 m-2 text-white bg-indigo-500 f fle btn hover:bg-indigo ">
                  <div className="mb-1 text-xl font-bold text-center text-white">
                  SOLENOID VALVE 6
                  </div>
                  <div
                    className={`p-0 border border-green-700 ${
                      (kajal?.[5]===1?"bg-green-700":"bg-red-700")
                        
                    } rounded-lg shadow-lg`}
                  >
                    <button className="px-5"></button>
                  </div>
                 
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow px-5 py-8 m-2 text-white bg-indigo-500 f fle btn hover:bg-indigo ">
                  <div className="mb-1 text-xl font-bold text-center text-white">
                  SOLENOID VALVE 7
                  </div>
                  <div
                    className={`p-0 border border-green-700 ${
                      (kajal?.[6]===1?"bg-green-700":"bg-red-700")
                        
                    } rounded-lg shadow-lg`}
                  >
                    <button className="px-5"></button>
                  </div>
                 
                </div>



               
               
                
                
                
              </div>
             
              {/* status ends here */}

              {/* chart Cards */}
              {/* <div className="flex flex-wrap items-center justify-center gap-3 mb-2 ">
              <div className="flex-1 p-5 my-2 bg-gray-200 rounded-lg shadow-lg shadow-indigo-500/50 ">
                <div className="p-1 font-sans text-sm font-bold text-center">
                  REAL TIME 3-PHASE VOLTAGE
                </div>
                <PieChart
                  value1Label="VL1"
                  value2Label="VL2"
                  value3Label="VL3"
                  tag="VOLTAGE"
                  value1={realVoltageVL1}
                  value2={realVoltageVL2}
                  value3={realVoltageVL3}
                />
              </div>
              <div className="flex-1 p-5 my-2 bg-gray-200 rounded-lg shadow-lg shadow-indigo-500/50 ">
                <div className="p-1 font-sans text-sm font-bold text-center">
                  REAL TIME 3-PHASE CURRENT
                </div>
                <PieChart
                  value1Label="IL1"
                  value2Label="IL2"
                  value3Label="IL3"
                  tag="CURRENT"
                  value1={realCurrentIL1}
                  value2={realCurrentIL2}
                  value3={realCurrentIL3}
                />
              </div>
              
            </div> */}

              {/* <div className="flex flex-wrap items-center justify-center gap-3 mb-2 ">
              
            </div> */}
              {/* <div className="flex flex-wrap items-center justify-center gap-3 mb-2">
              <div className="flex-1 p-5 my-2 bg-gray-200 rounded-lg shadow-lg shadow-indigo-500/50 ">
                <div className="p-2 font-serif text-xl font-bold text-center">
                  Power<sub></sub> Trend
                </div>
                <Linechart
                  dataArr={powervalueArray}
                  timeArr={powertimeeArray}
                  divider={1}
                  tag="Power"
                  colour="rgb(138,43,226)"
                  min={0}
                  max={10}
                  date={selectedDate}
                />
              </div>
              
            </div> */}
              {/* <div className="flex flex-wrap items-center justify-center gap-3 mb-2">
              <div className="flex-1 p-5 my-2 bg-gray-200 rounded-lg shadow-lg shadow-indigo-500/50 ">
                <div className="p-2 font-serif text-xl font-bold text-center">
                  Energy<sub> / Hour</sub> Trend
                </div>
                <Barchart
                  dataArr={energyPerHour}
                  timeArr={timeHour}
                  divider={1}
                  tag="Energy"
                  colour="rgb(138,43,226)"
                  min={0}
                  max={10}
                  date={selectedDate}
                />
              </div>
            </div> */}
            </div>

             {/* manual cards WITH RCT TIME*/}
             <div className="flex flex-col flex-wrap p-4 my-3 mb-2 font-sans bg-blue-200 rounded-lg shadow-md bg-opacity-70 backdrop-filter backdrop-blur-lg">
                <div className="flex flex-wrap">
                  {/* MANUAL START  */}
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow px-5 py-8 m-2 text-white bg-indigo-900 f fle btn hover:bg-indigo ">
                  <div className="mb-1 text-xl font-bold text-center text-white">
                  MANUAL START
                  </div>
                 
                  <button
                            onClick={() => handleManualStart()
                              // sendmsg(panelinfo.imei, manualStartMessage)
                            }
                            className="btn bg-[#3473e0] text-white p-1  text-xs mx-2"
                          >
                            START
                          </button>
                 
                </div>
                {/* MANUAL STOP  */}
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow px-5 py-8 m-2 text-white bg-indigo-900 f fle btn hover:bg-indigo ">
                  <div className="mb-1 text-xl font-bold text-center text-white">
                  MANUAL STOP
                  </div>
                  <button
                            onClick={() => handleManualStop()
                              // sendmsg(panelinfo.imei, manualStartMessage)
                            }
                            className="btn bg-[#3473e0] text-white p-1  text-xs mx-2"
                          >
                            STOP
                          </button>
                 
                </div>
                {/* RTC TIME  */}
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow px-5 py-8 m-2 text-white bg-indigo-900 f fle btn hover:bg-indigo ">
                  <div className="mb-1 text-xl font-bold text-center text-white">
                  RTC TIME
                  </div>
                  {/* <div
                    className={`p-0 border border-green-700 ${
                      (kajal?.[6]===1?"bg-green-700":"bg-red-700")
                        
                    } rounded-lg shadow-lg`}
                  >
                    <button className="px-5"></button>
                  </div> */}
                  {convertToHM(kajal?.[17])}
                  
                 
                </div>
                {/* CYCLE STATUS  */}
                
                </div>
                </div>

                
{/* config table  */}
            <div className="flex flex-col flex-wrap p-4 my-3 mb-2 font-sans bg-gray-200 rounded-lg shadow-md bg-opacity-70 backdrop-filter backdrop-blur-lg">
              <div className="font-sans text-2xl font-bold text-center text-gray-800 ">
                SET CLEANING TIME
              </div>

              <div className="m-auto sm:mx-5 lg:mx-6 md:mx-6 xl:mx-8 2xl:mx-10 ">
                <div className="p-5 m-1 overflow-auto font-sans bg-white rounded-lg shadow bg-opacity-70 backdrop-filter backdrop-blur-lg">
                  <table className="w-full mb-3 table-auto">
                    <thead className="border-b-2 border-gray-200">
                      <tr>
                        <th className="border  p-2 bg-[#3473e0] text-white  tracking-wide text-center text-sm font-bold  ">
                          SLOT
                        </th>
                        <th className="border  p-2 bg-[#3473e0] text-white tracking-wide text-center text-sm font-bold ">
                          CYCLE START TIME
                        </th>
                        <th className="border  p-2 bg-[#3473e0] text-white  tracking-wide text-center text-sm font-bold ">
                          CYCLE STOP TIME
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {/* slot 1 */}
                      <tr className="bg-gray-100">
                        <td className="p-2 font-bold text-center border">
                        SLOT 1
                        </td>
                        <td className="p-2 border ">
                          <input
                            type="time"
                            value={slot1startT}
                            onChange={handleslot1StartT}
                            className="p-1 mx-2 border-gray-100 rounded-lg"
                          />
                          <button
                            onClick={() =>
                              sendmsg(panelinfo.imei, slot1startTMessage)
                            }
                            className="btn bg-[#3473e0] text-white p-1  text-xs mx-2"
                          >
                            UPDATE
                          </button>
                        </td>
                        <td className="p-2 border">
                          <input
                            type="time"
                            value={slot1stopT}
                            onChange={handleslot1stopT}
                            className="p-1 mx-2 border-gray-100 rounded-lg "
                          />
                          <button
                            onClick={() =>
                              sendmsg(panelinfo.imei, slot1stopTMessage)
                            }
                            className="btn bg-[#3473e0] text-white p-1  text-xs mx-2"
                          >
                            UPDATE
                          </button>
                        </td>
                      </tr>
                      {/* slot 2 */}
                      <tr className="bg-blue-100">
                        <td className="p-2 font-bold text-center border">
                        SLOT 2
                        </td>
                        <td className="p-2 border ">
                          <input
                            type="time"
                            value={slot2startT}
                            onChange={handleslot2StartT}
                            className="p-1 mx-2 border-gray-100 rounded-lg"
                          />
                          <button
                            onClick={() =>
                              sendmsg(panelinfo.imei, slot2startTMessage)
                            }
                            className="btn bg-[#3473e0] text-white p-1  text-xs mx-2"
                          >
                            UPDATE
                          </button>
                        </td>
                        <td className="p-2 border">
                          <input
                            type="time"
                            value={slot2stopT}
                            onChange={handleslot2stopT}
                            className="p-1 mx-2 border-gray-100 rounded-lg "
                          />
                          <button
                            onClick={() =>
                              sendmsg(panelinfo.imei, slot2stopTMessage)
                            }
                            className="btn bg-[#3473e0] text-white p-1  text-xs mx-2"
                          >
                            UPDATE
                          </button>
                        </td>
                      </tr>
                      {/* slot 3 */}
                      <tr className="bg-gray-100">
                        <td className="p-2 font-bold text-center border">
                        SLOT 3
                        </td>
                        <td className="p-2 border ">
                          <input
                            type="time"
                            value={slot3startT}
                            onChange={handleslot3StartT}
                            className="p-1 mx-2 border-gray-100 rounded-lg"
                          />
                          <button
                            onClick={() =>
                              sendmsg(panelinfo.imei, slot3startTMessage)
                            }
                            className="btn bg-[#3473e0] text-white p-1  text-xs mx-2"
                          >
                            UPDATE
                          </button>
                        </td>
                        <td className="p-2 border">
                          <input
                            type="time"
                            value={slot3stopT}
                            onChange={handleslot3stopT}
                            className="p-1 mx-2 border-gray-100 rounded-lg "
                          />
                          <button
                            onClick={() =>
                              sendmsg(panelinfo.imei, slot3stopTMessage)
                            }
                            className="btn bg-[#3473e0] text-white p-1  text-xs mx-2"
                          >
                            UPDATE
                          </button>
                        </td>
                      </tr>
                      {/* slot 4 */}
                      <tr className="bg-blue-100">
                        <td className="p-2 font-bold text-center border">
                        SLOT 4
                        </td>
                        <td className="p-2 border ">
                          <input
                            type="time"
                            value={slot4startT}
                            onChange={handleslot4StartT}
                            className="p-1 mx-2 border-gray-100 rounded-lg"
                          />
                          <button
                            onClick={() =>
                              sendmsg(panelinfo.imei, slot4startTMessage)
                            }
                            className="btn bg-[#3473e0] text-white p-1  text-xs mx-2"
                          >
                            UPDATE
                          </button>
                        </td>
                        <td className="p-2 border">
                          <input
                            type="time"
                            value={slot4stopT}
                            onChange={handleslot4stopT}
                            className="p-1 mx-2 border-gray-100 rounded-lg "
                          />
                          <button
                            onClick={() =>
                              sendmsg(panelinfo.imei, slot4stopTMessage)
                            }
                            className="btn bg-[#3473e0] text-white p-1  text-xs mx-2"
                          >
                            UPDATE
                          </button>
                        </td>
                      </tr>
                      {/* slot 5 */}
                      <tr className="bg-gray-100">
                        <td className="p-2 font-bold text-center border">
                        TIMER E/D
                        </td>
                        <td className="p-2 border ">
                          <input
                            type="time"
                            value={0}
                            onChange={0}
                            className="p-1 mx-2 border-gray-100 rounded-lg"
                          />
                          <button
                            // onClick={() =>
                            //   sendmsg(panelinfo.imei, slot5startTMessage)
                            // }
                            className="btn bg-[#3473e0] text-white p-1  text-xs mx-2"
                          >
                            UPDATE
                          </button>
                        </td>
                        <td className="p-2 border">
                          <input
                            type="time"
                            value={0}
                            onChange={0}
                            className="p-1 mx-2 border-gray-100 rounded-lg "
                          />
                          <button
                            // onClick={() =>
                            //   sendmsg(panelinfo.imei, slot4runTimeMessage)
                            // }
                            className="btn bg-[#3473e0] text-white p-1  text-xs mx-2"
                          >
                            UPDATE
                          </button>
                        </td>
                      </tr>
                     
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

{/* valve table  */}
            <div className="flex flex-col flex-wrap p-4 my-3 mb-2 font-sans bg-gray-200 rounded-lg shadow-md bg-opacity-70 backdrop-filter backdrop-blur-lg">
              <div className="font-sans text-2xl font-bold text-center text-gray-800 ">
                SET SOLENOID VALVES TIME
              </div>

              <div className="m-auto sm:mx-5 lg:mx-6 md:mx-6 xl:mx-8 2xl:mx-10 ">
                <div className="p-5 m-1 overflow-auto font-sans bg-white rounded-lg shadow bg-opacity-70 backdrop-filter backdrop-blur-lg">
                  <table className="w-full mb-3 table-auto">
                    <thead className="border-b-2 border-gray-200">
                      <tr>
                        <th className="border  p-2 bg-[#3473e0] text-white  tracking-wide text-center text-sm font-bold  ">
                          VALVE
                        </th>
                        <th className="border  p-2 bg-[#3473e0] text-white tracking-wide text-center text-sm font-bold ">
                          RUNNING TIME
                        </th>
                        {/* <th className="border  p-2 bg-[#3473e0] text-white  tracking-wide text-center text-sm font-bold ">
                          CYCLE-2 STOP-TIME//OFF-TIME
                        </th> */}
                      </tr>
                    </thead>

                    <tbody>
                      {/* slot 1 */}
                      <tr className="bg-gray-100">
                        <td className="p-2 font-bold text-center border">
                        SOLENOID VALVE 1
                        </td>
                        <td className="p-2 border ">
                          <input
                            type="number"
                            value={sv1startT}
                            onChange={handlesv1startT}
                            className="p-1 mx-2 border-gray-100 rounded-lg"
                          />
                          <button
                            onClick={() =>
                              sendmsg(panelinfo.imei, sv1startTMessage)
                            }
                            
                            className="btn bg-[#3473e0] text-white p-1  text-xs mx-2"
                          >
                            UPDATE
                          </button>
                        </td>
                       
                      </tr>
                      {/* slot 2 */}
                      <tr className="bg-blue-100">
                        <td className="p-2 font-bold text-center border">
                        SOLENOID VALVE 2
                        </td>
                        <td className="p-2 border ">
                          <input
                            type="number"
                            value={sv2startT}
                            onChange={handlesv2startT}
                            className="p-1 mx-2 border-gray-100 rounded-lg"
                          />
                          <button
                            onClick={() =>
                              sendmsg(panelinfo.imei, sv2startTMessage)
                            }
                            className="btn bg-[#3473e0] text-white p-1  text-xs mx-2"
                          >
                            UPDATE
                          </button>
                        </td>
                        
                      </tr>
                      {/* slot 3 */}
                      <tr className="bg-gray-100">
                        <td className="p-2 font-bold text-center border">
                        SOLENOID VALVE 3
                        </td>
                        <td className="p-2 border ">
                          <input
                            type="number"
                            value={sv3startT}
                            onChange={handlesv3startT}
                            className="p-1 mx-2 border-gray-100 rounded-lg"
                          />
                          <button
                            onClick={() =>
                              sendmsg(panelinfo.imei, sv3startTMessage)
                            }
                            className="btn bg-[#3473e0] text-white p-1  text-xs mx-2"
                          >
                            UPDATE
                          </button>
                        </td>
                       
                      </tr>
                      {/* slot 4 */}
                      <tr className="bg-blue-100">
                        <td className="p-2 font-bold text-center border">
                        SOLENOID VALVE 4
                        </td>
                        <td className="p-2 border ">
                          <input
                            type="number"
                            value={sv4startT}
                            onChange={handlesv4startT}
                            className="p-1 mx-2 border-gray-100 rounded-lg"
                          />
                          <button
                            onClick={() =>
                              sendmsg(panelinfo.imei, sv4startTMessage)
                            }
                            className="btn bg-[#3473e0] text-white p-1  text-xs mx-2"
                          >
                            UPDATE
                          </button>
                        </td>
                        
                      </tr>
                      {/* slot 5 */}
                      <tr className="bg-gray-100">
                        <td className="p-2 font-bold text-center border">
                        SOLENOID VALVE 5
                        </td>
                        <td className="p-2 border ">
                          <input
                            type="number"
                            value={sv5startT}
                            onChange={handlesv5startT}
                            className="p-1 mx-2 border-gray-100 rounded-lg"
                          />
                          <button
                            onClick={() =>
                              sendmsg(panelinfo.imei, sv5startTMessage)
                            }
                            className="btn bg-[#3473e0] text-white p-1  text-xs mx-2"
                          >
                            UPDATE
                          </button>
                        </td>
                       
                      </tr>
                      {/* slot 6 */}
                      <tr className="bg-blue-100">
                        <td className="p-2 font-bold text-center border">
                        SOLENOID VALVE 6
                        </td>
                        <td className="p-2 border ">
                          <input
                            type="number"
                            value={sv6startT}
                            onChange={handlesv6startT}
                            className="p-1 mx-2 border-gray-100 rounded-lg"
                          />
                          <button
                            onClick={() =>
                              sendmsg(panelinfo.imei, sv6startTMessage)
                            }
                            className="btn bg-[#3473e0] text-white p-1  text-xs mx-2"
                          >
                            UPDATE
                          </button>
                        </td>
                        
                      </tr>
                      <tr className="bg-blue-100">
                        <td className="p-2 font-bold text-center border">
                        SOLENOID VALVE 7
                        </td>
                        <td className="p-2 border ">
                          <input
                            type="number"
                            value={sv7startT}
                            onChange={handlesv7startT}
                            className="p-1 mx-2 border-gray-100 rounded-lg"
                          />
                          <button
                            onClick={() =>
                              sendmsg(panelinfo.imei, sv7startTMessage)
                            }
                            className="btn bg-[#3473e0] text-white p-1  text-xs mx-2"
                          >
                            UPDATE
                          </button>
                        </td>
                        
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
