import React from "react";

function WelcomeBanner() {
  return (
    <div
      className="relative p-4 overflow-hidden rounded-sm m sm:p-6"
      style={{ position: "relative" }}
    >
      {/* Image background */}
      <img
        src="images/SolarPanel.webp"
        alt="Background Image"
        className="absolute inset-0 w-full h-full object-cover rounded-xl mb-2"
        style={{ position: "absolute", width: "100%", height: "100%" }}
      />

      {/* Content with text overlay */}
      <div className="relative">
        <h1
          className="mb-1 text-3xl font-bold text-black md:text-3xl bg-opacity-90 backdrop-filter backdrop-blur-lg rounded-lg shadow-xs p-4 text-center font-sans"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
        >
          AUTOMATIC SOLAR PANEL CLEANER
        </h1>
        {/* Additional content can be placed here */}
      </div>
    </div>

    // <div className="relative p-4 overflow-hidden  rounded-sm m sm:p-6">
    //   {/* Content */}

    //   <div className="relative">
    //     <h1 className="mb-1 text-2xl font-bold text-black md:text-3xl bg-white bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-lg shadow-xs p-4 text-center font-sans">
    //       AUTOMATIC SOLAR PANEL CLEANER
    //     </h1>

    //   </div>

    // </div>
  );
}

export default WelcomeBanner;
